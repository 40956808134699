$sidebar-color: $white;
$submenu-bg-color: $darksgray;
$icon-bg-color: $darkslategray;
$sidebar-bg-color: $darkslategray;

$icon-size: 45px;
$sidebar-width: 268px;
$sidebar-collapsed-width: 70px;

//$highlight-color: #d8d8d8 !default;
//$submenu-bg-color-collapsed: #2b2b2b !default
//$breakpoint-xs: 480px !default;
//$breakpoint-sm: 576px !default;
//$breakpoint-md: 768px !default;
//$breakpoint-lg: 992px !default;
//$breakpoint-xl: 1200px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar {
  .pro-menu {
    &-item {
      &.active {
        background-color: $white;
        border-left: 5px solid $primary;

        .pro-icon {
          color: $gray-darker;
        }

        .pro-icon-wrapper {
          background-color: $white !important;
        }

        .pro-item-content {
          a {
            color: $gray-darker !important;
          }
        }
      }

      &.pro-sub-menu {
        .pro-inner-list-item {
          .pro-menu-item {
            border-left: none;
            background-color: $submenu-bg-color;
            &.active {
              border-right: 5px solid $primary;

              .pro-icon-wrapper {
                background-color: $gray-dark !important;
              }
            }

            .pro-inner-item {
              &:before {
                content: none !important;
              }

              & > .pro-icon-wrapper {
                display: block !important;
                background-color: $submenu-bg-color !important;

                .pro-icon {
                  color: $white !important;
                }
              }

              & > .pro-item-content {
                a {
                  color: $white !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

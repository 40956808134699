html {
    touch-action: manipulation;
    -ms-touch-action: manipulation;
}

html, body, #root {
    height: 100%;
    min-height: 100%;
    position: relative;
}

/* ========================================================================
     Component: print
     Put here all rules required to style the print view
 ========================================================================== */

@media print {
    #menu, #header, .no-print, .btn {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
    }

    #content  {
        width: 100% !important;
    }
}


// toastr
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

@import '~animate.css/animate.min.css';
// Override bootstrap variables
@import 'scss/variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';
// country code bootstrap
@import '~react-phone-input-2/lib/bootstrap.css';
// application common styles
@import 'scss/mixin';
@import 'scss/layout/index';
@import 'scss/component/index';
@import 'scss/addon';
@import 'scss/print';
@import 'scss/typo';
// specific pages styles
@import './scss';


.rf-input-phone {

  & > .rf-input-phone-field {

    & > .form-control {
      width: 100%;
    }
  }
}

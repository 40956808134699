
.hint {
  flex: 0;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include rect-size(32px);

  color: #FFFFFF;
  background-color: $warning;
  box-shadow: inset 0 0 4px 1px white, inset -1px -1px 8px 2px lighten($warning, 20%);

  .hint-icon {
    margin-top: -2px;
  }
}

.hint-popper {
  $bg: $white;
  $color: $body-color;
  &.show { opacity: 1; }

  .tooltip-inner {
    color: $color;
    text-align: left;
    max-width: 300px;
    background-color: $bg;
    border: 1px solid $color;
  }

  .arrow:before {
    border-top-color: $color !important;
  }
}

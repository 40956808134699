
@import 'verify';

#PropertiesList {
  padding: 20px;

  .app-preloader {

  }
}


.app-tooltip {

}

.app-tooltip-popper{
  $bg: $white;
  $color: $body-color;
  &.show { opacity: 1; }

  .tooltip-inner {
    color: $color;
    text-align: left;
    max-width: 300px;
    background-color: $bg;
    border: 1px solid $color;
  }

  .arrow:before {
    border-top-color: $color !important;
  }
}


#VideoRequests {
  padding: 20px;
}

.details-modal, .close-request-modal {
    top: 10%;
    font-size: 18px;

    .close {
      font-size: 40px;
    }
}

.rf-select {

  &.is-valid {
    .rf-select-field__control {
      border-color: $success;
    }
  }

  &.is-invalid {
    .rf-select-field__control {
      border-color: $danger;
    }
  }

  // NOTE please @see { @link https://react-select.com/styles }
  &-field {
    &__control {
      border-color: $input-border-color;
    }

    &__container {
      color: $input-color;
    }

    &__menu {
      z-index: $zindex-dropdown;
    }

    &__input {
      height: 48px;
      color: $input-color;
    }

    &__placeholder {
      color: $input-placeholder-color;
    }

    &__option {
      height: 48px;
      display: flex;
      align-items: center;
    }
  }
}

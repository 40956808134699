
// Cutoms Gray colors for theme
$gray-darker:           #232735;
$gray-dark:             #3a3f51;
$darksgray:             #323030;
$darkslategray:         #3c3c3b;
$gray-d:                #A5A5A5;
$gray:                  #dde6e9;
$gray-light:            #e4eaec;
$gray-lighter:          #edf1f2;

// Custom brand colors for theme
$inverse:               #131e26;
$purple:                #7266ba;
$pink:                  #f532e5;
$yellow:                #fad732;
$green:                 #37bc9b;
$whitesmoke:            #f3f6f8;

$primary:               #31a3b5;
$secondary:             #ffffff;
$success:               #27c24c;
$info:                  #23b7e5;
$warning:               #ff902b;
$danger:                #f05050;
$dark:                  #3a3f51;

// Custom color variants
$amountOfLight: 10%;

$primary-light:         lighten($primary, $amountOfLight);
$success-light:         lighten($success, $amountOfLight);
$info-light:            lighten($info, $amountOfLight);
$warning-light:         lighten($warning, $amountOfLight);
$danger-light:          lighten($danger, $amountOfLight);
$inverse-light:         lighten($inverse, $amountOfLight);
$green-light:           lighten($green, $amountOfLight);
$pink-light:            lighten($pink, $amountOfLight);
$purple-light:          lighten($purple, $amountOfLight);
$yellow-light:          lighten($yellow, $amountOfLight);
$primary-dark:          darken($primary, $amountOfLight);
$success-dark:          darken($success, $amountOfLight);
$info-dark:             darken($info, $amountOfLight);
$warning-dark:          darken($warning, $amountOfLight);
$danger-dark:           darken($danger, $amountOfLight);
$inverse-dark:          darken($inverse, $amountOfLight);
$green-dark:            darken($green, $amountOfLight);
$pink-dark:             darken($pink, $amountOfLight);
$purple-dark:           darken($purple, $amountOfLight);
$yellow-dark:           darken($yellow, $amountOfLight);

// Theme variables

// used for sidebars and content spacing
$navbar-height:                    55px;

// Top Border color for custom default cards (.card-default)
$card-default-border-color:        #cfdbe2;
//$card-cap-bg:                      rgba(0, 0, 0, 0.03);


// ---------------------
// Utilities variables
// ---------------------

$text-alpha:                  rgba(255,255,255,.5);
$text-alpha-inverse:          rgba(0,0,0,.5);

//$border-color:                rgba(0,0,0,.12);
$border-color:                #e4eaec;
//$input-btn-padding-y:         .35rem;
//$input-btn-padding-x:         .85rem;

//$input-btn-padding-y-sm:      .3125rem;
//$input-btn-padding-x-sm:      .625rem;

//$input-btn-padding-y-lg:      .625rem;
//$input-btn-padding-x-lg:      1.25rem;

//$input-height:                2.3rem; // 35px

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system
$colors: (
    "purple":     $purple,
    "pink":       $pink,
    "yellow":     $yellow,
    "green":      $green,
    "primary":    $primary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    'inverse':    $inverse,

    'primary-light': $primary-light,
    'primary-dark':  $primary-dark,
    'success-light': $success-light,
    'success-dark':  $success-dark,
    'info-light':    $info-light,
    'info-dark':     $info-dark,
    'warning-light': $warning-light,
    'warning-dark':  $warning-dark,
    'danger-light':  $danger-light,
    'danger-dark':   $danger-dark,
    'green-light':   $green-light,
    'green-dark':    $green-dark,
    'pink-light':    $pink-light,
    'pink-dark':     $pink-dark,
    'purple-light':  $purple-light,
    'purple-dark':   $purple-dark,
    'inverse-light': $inverse-light,
    'inverse-dark':  $inverse-dark,
    'yellow-light':  $yellow-light,
    'yellow-dark':   $yellow-dark,
);


$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "gray-d":     $gray-d
);

// Body
$body-bg:                           #f5f7fa;
$body-color:                        #656565;

//$headings-line-height:              1.1;
//$line-height-base:                  1.528571429;

//$border-radius:                     .25rem;
//$border-radius-lg:                  .375rem;

$text-muted:                        #909FA7;

// Tables
$table-hover-bg:                    #fafbfc;
$table-accent-bg:                   #fafbfc;
$table-border-color:                #eeeeee;

// Forms
$input-padding-y:                   1rem;
$input-disabled-bg:                 $gray-lighter;
$input-border-color:                #eaeaea;
$input-focus-border-color:          #66afe9;
$input-placeholder-color:           #b7bac9;

// Dropdowns
$dropdown-border-color:             #e1e1e1;

// Navs
$nav-tabs-link-active-bg:           #ffffff;

// Jumbotron
$jumbotron-bg:                      #ffffff; // #fcfcfc;

// List group
$list-group-border-color:           $border-color;

// Breadcrumbs
$breadcrumb-bg:                     #fafafa;
$breadcrumb-divider-color:          #c1c2c3;
$breadcrumb-active-color:           $body-color;


#EmailConfirmation {
    width: 390px;
    max-width: 95%;
    padding-top: 200px;

    .app-logo {
        width: 160px;
        height: auto;
    }
}

#InviteMembersModal {
  .close {
    font-size: 40px;
  }

  .user-item {
    display: flex;
    align-items: center;
  
    .checkbox {
      @include rect-size(18px);
    }
  
    .avatar {
      $size: 64px;
      overflow: hidden;
      position: relative;
      border-radius: 50%;
      @include rect-size($size);
    }
  }
}
